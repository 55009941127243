<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Documents Uploaded List</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-layout wrap justify-end>
          <v-flex xs12 text-right pb-3>
            <!-- <v-layout wrap justify-end>
              <v-flex lg5> </v-flex>
              <v-flex xs2 lg2 px-2>
                <span>
                 
                   <a
                    :href="'http://192.168.51.180:5000/job/application/search/downloadpdf?from='+ fromDate + '&to='+ toDate +'&category='+jobCategory + '&keyword='+apSearch "
                  >
                    <v-btn block dark color="#005f32">download pdf</v-btn>
                  </a>
                </span>
              </v-flex>
              <v-flex xs2 lg2 px-2>
                <download-excel :data="so" :fields="json_fields">
                  <v-btn block dark color="#005f32">
                    <span> Download Excel</span>
                  </v-btn>
                </download-excel>
              </v-flex>
            </v-layout> -->
          </v-flex>

          <!-- <v-flex lg3 pa-2 align-self-start text-right>
            <v-select
              outlined
              dense
              rounded
              :color="appColor"
              placeholder="Job Category"
              v-model="jobCategory"
              :items="categoryList"
              item-text="name"
              item-value="_id"
              item-color="#FF1313"
              hide-details
              class="text-left"
              clearable
            >
              <template v-slot:item="{ item }">
                <v-avatar>
                  <v-img
                    height="20px"
                    contain
                    :src="mediaURL + item.icon"
                  ></v-img>
                </v-avatar>
                &nbsp;
                <span class="kumbhRegular text-left">
                  {{ item.name }}
                </span>
              </template>
            </v-select>
          </v-flex> -->
          <!-- <v-flex lg3 pa-2 align-self-start>
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromDate"
                  label="From Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                no-title
                @input="menu11 = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex lg3 pa-2 align-self-start>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="toDate"
                  label="To Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-flex> -->
          <v-flex lg3 pa-2>
            <v-text-field
              class="rounded cl"
              prepend-inner-icon="mdi-magnify"
              outlined
              dense
              label="Search"
              v-model="apSearch"
              @change="getData"
            >
            </v-text-field>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-start v-if="employeeList.length > 0">
          <v-flex xs12 align-self-center>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <!-- <th class="text-left">Date</th> -->
                    <th class="text-left">Employee Name</th>
                    <th class="text-left">Phone</th>
                    <th class="text-left">Email</th>
                    <th class="text-left">Document</th>
                    <th class="text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="text-left align-center"
                    v-for="(item, i) in employeeList"
                    :key="i"
                  >
                    <!-- <td>
                      <span
                        style="font-family: kumbhMedium"
                        v-if="item.accepteddate"
                        >{{ DateFormatted(item.accepteddate) }}</span
                      >
                    </td> -->
                    <td>
                      <span
                        v-if="item"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.name }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item"
                        class=""
                        style="font-family: kumbhMedium"
                      >
                        <span v-if="item.phonenumber">
                          {{ item.phonenumber }}
                        </span>
                      </span>
                    </td>

                    <td>
                      <span
                        v-if="item.email"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.email }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.status == 'Complete'"
                        class=""
                        style="font-family: kumbhMedium"
                      >
                        <v-icon>mdi-file-document</v-icon>
                      </span>
                    </td>

                    <td>
                      <v-layout wrap justify-start>
                        <v-flex xs12 sm6 md4 pa-2 align-self-center>
                          <v-btn
                            color="#005f32"
                            style="font-family: kumbhBold"
                            x-small
                            dark
                            fab
                            icon
                            title="View"
                            outlined
                            depressed
                            :to="'/employeeview?id=' + item._id"
                          >
                            <v-icon>mdi-eye</v-icon></v-btn
                          >
                        </v-flex>
                        <v-flex xs12 sm6 md4 pa-2 align-self-center>
                          <v-dialog
                            persistent
                            v-model="item.delete"
                            max-width="600px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="#005f32"
                                style="font-family: kumbhBold"
                                x-small
                                dark
                                fab
                                title="Delete"
                                outlined
                                plain
                                depressed
                                v-on="on"
                                v-bind="attrs"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-title
                                >Are you sure you want to delete this employee
                              </v-card-title>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="item.delete = false"
                                  >Cancel</v-btn
                                >
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="deleteItem(item)"
                                  >OK</v-btn
                                >
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-flex>
                      </v-layout>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-start v-else>
          <v-flex py-16 xs12>
            <span class="RR"> No Data found! </span>
          </v-flex>
        </v-layout>
        <!-- <v-layout wrap justify-center v-if="pageCount > 0">
          <v-flex align-self-center>
            <div class="pt-2">
              <v-pagination
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
                :length="pages"
                :total-visible="7"
                v-model="currentPage"
                color="#005f32"
              ></v-pagination>
            </div>
          </v-flex>
        </v-layout> -->
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
    <v-layout wrap pt-3>
      <v-flex xs12 text-center>
        <v-pagination
          v-model="page"
          color="#005f32"
          :length="pagelength"
        ></v-pagination>
      </v-flex>
    </v-layout>

    <v-dialog persistent v-model="editdialog" max-width="70%">
      <v-card tile>
        <v-card-title>
          <span class="headline">Edit Category</span>
        </v-card-title>
        <v-card-text>
          <v-layout wrap>
            <v-flex xs12 pt-5 text-left>
              <v-text-field
                v-model="editingitem.name"
                label="Category Name"
                :rules="[rules.required]"
                outlined
                dense
                required
              >
              </v-text-field>
            </v-flex>

            <v-flex xs12 align-self-center text-left pt-5>
              <v-select
                v-model="editingitem.jobCategory"
                :items="categoryList"
                item-text="name"
                item-value="_id"
                item-color="#8d8d8d"
                color="#8d8d8d"
                outlined
                dense
                label="Job Category"
              >
              </v-select>
            </v-flex>
            <v-flex xs12 align-self-center text-left pt-5>
              <span>Content</span>
              <br />
              <vue-editor
                class="textField2 pt-1"
                v-model="editingitem.content"
              ></vue-editor>
            </v-flex>
            <v-flex xs12 align-self-center text-left pt-5>
              <v-text-field
                v-model="editingitem.location"
                label="Location"
                :rules="[rules.required]"
                required
                outlined
                dense
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="getdData"> Close </v-btn>
          <v-btn
            v-if="!g"
            color="blue darken-1"
            text
            @click="edit(editingitem._id)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      employeeList: [],
      page: 1,
      limit: 20,
      pagelength: 5,

      //abov employee lines
      json_fields: {
        Date: "Edate",
        name: "name",
        JobCategory: "cat",
        JobTitle: "title",
      },

      exel: {
        name: "",
        cat: "",
        Edate: "",
        title: "",
      },

      so: [],

      apSearch: null,
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      content: "",
      location: "",
      jobCategory: null,
      categoryList: [],
      titleList: [],
      pageCount: 0,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      category: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      flagg: true,
      date: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
    };
  },
  components: {
    // ImageComp,
  },
  mounted() {
    if (localStorage.PRE == "/employeeview") {
      if (localStorage.fromDateE != "null") {
        this.fromDate = localStorage.fromDateE;
      } else {
        this.fromDate = "";
      }
      if (localStorage.toDateE != "null") {
        this.toDate = localStorage.toDateE;
      } else {
        this.toDate = "";
      }

      if (localStorage.Esearch != "null") {
        this.apSearch = localStorage.Esearch;
      } else {
        this.apSearch = "";
      }
    }

    // if (this.fromDate) {
    //   // this.fromDate = null;
    //   this.fromDate.setDate(this.fromDate.getDate());
    //   this.fromDate = this.fromDate.toISOString().substr(0, 10);
    // }
    // if (this.toDate) {
    //   // this.toDate = null;
    //   this.toDate.setDate(this.toDate.getDate());
    //   this.toDate = this.toDate.toISOString().substr(0, 10);
    // }
    this.getData();
  },
  watch: {
    page() {
      this.getdData();
    },

    // fromDate() {
    //   // this.fromDate = this.fromDate.toISOString().substr(0, 10);
    //   this.getData();
    // },
    // toDate() {
    //   // this.toDate = this.fromDate.toISOString().substr(0, 10);
    //   this.getData();
    // },
    // apSearch()
    // {
    //   this.keySearch();
    // }
  },

  methods: {
    getData() {
      localStorage.setItem("fromDateE", this.fromDate);
      // console.log("fromDateE", this.fromDate);
      localStorage.setItem("toDateE", this.toDate);
      localStorage.setItem("Esearch", this.apSearch);

      this.appLoading = true;
      axios({
        url: "/admin/view/employeelist",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          // from: this.fromDate,
          // to: this.toDate,
          keyword: this.apSearch,
          page: this.page,
          limit: this.limit,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.employeeList = response.data.data;
            this.pagelength = Math.ceil(
              response.data.length / response.data.limit
            );
            // console.log(response.data.data, "jjjjj");
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    DateFormatted(params) {
      params = moment(params).subtract(5, "h").subtract(30, "m");
      return params ? moment(params).format("DD-MMM-Y") : "";
    },
    getdData() {
      this.editdialog = false;
      this.getData();
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/admin/remove/employee",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    // search
    keySearch() {
      this.appLoading = true;
      axios({
        url: "/employee/search",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          keyword: this.apSearch,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.employeeList = response.data.data;
          this.pagelength = Math.ceil(response.data.length / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    //end
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    categoryadd() {
      var data = {};
      data["name"] = this.name;
      data["jobCategory"] = this.jobCategory;
      data["location"] = this.location;
      data["content"] = this.content;
      axios({
        url: "/job/title/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.name = null;
            this.jobCategory = null;
            this.location = null;
            this.content = null;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["name"] = this.editingitem.name;
      data["jobCategory"] = this.editingitem.jobCategory;
      data["location"] = this.editingitem.location;
      data["content"] = this.editingitem.content;
      axios({
        url: "/job/title/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            // if (this.coverImageFile) {
            //   this.uploadCoverImages(this.editingitem._id);
            // }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.cl >>> fieldset {
  border-color: rgba(250, 162, 0, 0.986);
  border-radius: 50px;
}
</style>
